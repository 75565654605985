@charset "UTF-8";
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/open-sans-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("./fonts/open-sans-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-300.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-300.woff") format("woff"), url("./fonts/open-sans-v15-latin-300.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/open-sans-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("./fonts/open-sans-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-regular.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-regular.woff") format("woff"), url("./fonts/open-sans-v15-latin-regular.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/open-sans-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("./fonts/open-sans-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-700.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-700.woff") format("woff"), url("./fonts/open-sans-v15-latin-700.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body .ui-widget {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  text-decoration: none; }
body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px; }
body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body a {
  color: #007ad9;
  text-decoration: none; }
  body a:hover {
    color: #116fbf; }
  body a:active {
    color: #005b9f; }
body .ui-helper-reset {
  line-height: normal; }
body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }
body .pi {
  font-size: 1.25em; }

body {
  /* Validations */ }
  body .ui-inputtext {
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.429em;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    body .ui-inputtext:enabled:hover:not(.ui-state-error) {
      border-color: #212121; }
    body .ui-inputtext:enabled:focus:not(.ui-state-error) {
      border-color: #007ad9;
      outline: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
  body .ui-chkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px; }
    body .ui-chkbox .ui-chkbox-box {
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      width: 20px;
      height: 20px;
      text-align: center;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s; }
      body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
        border-color: #212121; }
      body .ui-chkbox .ui-chkbox-box.ui-state-focus {
        border-color: #007ad9;
        background-color: #ffffff;
        color: #007ad9;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
      body .ui-chkbox .ui-chkbox-box.ui-state-active {
        border-color: #007ad9;
        background-color: #007ad9;
        color: #ffffff; }
        body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
          border-color: #005b9f;
          background-color: #005b9f;
          color: #ffffff; }
        body .ui-chkbox .ui-chkbox-box.ui-state-active.ui-state-focus {
          border-color: #007ad9;
          background-color: #007ad9;
          color: #ffffff; }
      body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
        overflow: hidden;
        position: relative;
        font-size: 18px; }
  body .ui-chkbox-label {
    margin: 0 0 0 0.5em; }
  body .ui-radiobutton {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px; }
    body .ui-radiobutton .ui-radiobutton-box {
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      width: 20px;
      height: 20px;
      text-align: center;
      position: relative;
      -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
        border-color: #212121; }
      body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
        border-color: #007ad9;
        background-color: #ffffff;
        color: #007ad9;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
      body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
        border-color: #007ad9;
        background-color: #007ad9;
        color: #ffffff; }
        body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
          background-color: #ffffff; }
        body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
          border-color: #005b9f;
          background-color: #005b9f;
          color: #ffffff; }
        body .ui-radiobutton .ui-radiobutton-box.ui-state-active.ui-state-focus {
          border-color: #007ad9;
          background-color: #007ad9;
          color: #ffffff; }
      body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
        background: transparent;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        -moz-transition: background-color 0.2s;
        -o-transition: background-color 0.2s;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%; }
        body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
          display: none; }
  body .ui-radiobutton-label {
    margin: 0 0 0 .5em; }
  body .ui-inputswitch {
    width: 3em;
    height: 1.75em; }
    body .ui-inputswitch .ui-inputswitch-slider {
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      border-radius: 30px;
      background: #cccccc; }
      body .ui-inputswitch .ui-inputswitch-slider:before {
        background-color: #ffffff;
        height: 1.250em;
        width: 1.250em;
        left: .25em;
        bottom: .25em;
        border-radius: 50%;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
    body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
      -webkit-transform: translateX(1.25em);
      -ms-transform: translateX(1.25em);
      transform: translateX(1.25em); }
    body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
      background: #b7b7b7; }
    body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
      background-color: #b7b7b7; }
    body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
      background-color: #007ad9; }
    body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
      background-color: #116fbf; }
    body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
      background: #005b9f; }
  body .ui-autocomplete .ui-autocomplete-input {
    padding: 0.429em; }
  body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
    padding: 0.2145em 0.429em; }
    body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
      border-color: #212121; }
    body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
      border-color: #007ad9;
      outline: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
      margin: 0;
      padding: 0.2145em 0;
      color: #333333; }
      body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0; }
    body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
      font-size: 14px;
      padding: 0.2145em 0.429em;
      margin: 0 0.286em 0 0;
      background: #007ad9;
      color: #ffffff; }
  body .ui-autocomplete-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-autocomplete-panel .ui-autocomplete-items {
      padding: 0; }
      body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
          color: #ffffff;
          background-color: #007ad9; }
      body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
        padding: 0.429em 0.857em;
        background-color: #d8dae2;
        color: #333333; }
  body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
    width: 2.357em; }
  body .ui-fluid .ui-autocomplete.ui-autocomplete-multiple.ui-autocomplete-dd .ui-autocomplete-multiple-container {
    border-right: 0 none;
    width: calc(100% - 2.357em); }
  body .ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-inputtext {
    border-right: 0 none;
    width: calc(100% - 2.357em); }
  body .ui-chips > ul.ui-inputtext {
    padding: 0.2145em 0.429em;
    display: inline-block; }
    body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
      border-color: #212121; }
    body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
      border-color: #007ad9;
      outline: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    body .ui-chips > ul.ui-inputtext .ui-chips-input-token {
      padding: 0.2145em 0; }
      body .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0;
        color: #333333; }
        body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover {
          border: 0 none; }
        body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
          border: 0 none; }
    body .ui-chips > ul.ui-inputtext .ui-chips-token {
      font-size: 14px;
      padding: 0.2145em 0.429em;
      margin: 0 0.286em 0 0;
      background: #007ad9;
      color: #ffffff; }
  body .ui-dropdown {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s; }
    body .ui-dropdown:not(.ui-state-disabled):hover {
      border-color: #212121; }
    body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
      border-color: #007ad9;
      outline: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    body .ui-dropdown .ui-dropdown-label {
      padding-right: 2em; }
    body .ui-dropdown .ui-dropdown-trigger {
      background-color: #ffffff;
      width: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: #848484; }
    body .ui-dropdown .ui-dropdown-clear-icon {
      color: #848484; }
    body .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
      padding-right: 4em; }
  body .ui-dropdown-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-dropdown-panel .ui-dropdown-filter-container {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0; }
      body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
        width: 100%;
        padding-right: 2em; }
      body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
        top: 50%;
        margin-top: -.5em;
        right: 1.357em;
        color: #007ad9; }
    body .ui-dropdown-panel .ui-dropdown-items {
      padding: 0; }
      body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
          color: #ffffff;
          background-color: #007ad9; }
        body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
          color: #333333;
          background-color: #eaeaea; }
  body .ui-multiselect {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s;
    -o-transition: border-color 0.2s;
    -webkit-transition: border-color 0.2s;
    transition: border-color 0.2s; }
    body .ui-multiselect:not(.ui-state-disabled):hover {
      border-color: #212121; }
    body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
      border-color: #007ad9;
      outline: 0 none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
    body .ui-multiselect .ui-multiselect-label {
      padding: 0.429em;
      padding-right: 2em;
      color: #333333; }
    body .ui-multiselect .ui-multiselect-trigger {
      background-color: #ffffff;
      width: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: #848484; }
  body .ui-multiselect-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-multiselect-panel .ui-multiselect-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0; }
      body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
        float: none;
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0; }
        body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
          padding: 0.429em;
          padding-right: 2em; }
        body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
          color: #007ad9;
          top: 50%;
          margin-top: -.5em;
          right: .5em;
          left: auto; }
      body .ui-multiselect-panel .ui-multiselect-header .ui-chkbox {
        margin-right: 0.5em;
        float: none;
        vertical-align: middle; }
      body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
        color: #848484;
        top: 50%;
        margin-top: -.5em;
        -moz-transition: color 0.2s;
        -o-transition: color 0.2s;
        -webkit-transition: color 0.2s;
        transition: color 0.2s; }
        body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:hover {
          color: #333333; }
    body .ui-multiselect-panel .ui-multiselect-items {
      padding: 0; }
      body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
          color: #ffffff;
          background-color: #007ad9; }
        body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
          color: #333333;
          background-color: #eaeaea; }
        body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box {
          cursor: auto; }
          body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box:hover {
            border: 1px solid #a6a6a6; }
          body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box.ui-state-active:hover {
            border-color: #007ad9; }
        body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.5em 0 0; }
        body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
          display: inline-block;
          vertical-align: middle; }
  body .ui-listbox {
    padding: 0;
    min-width: 12em;
    background: #ffffff;
    border: 1px solid #a6a6a6; }
    body .ui-listbox .ui-chkbox {
      margin: 0 0.5em 0 0; }
    body .ui-listbox .ui-listbox-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0; }
      body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
        width: calc(100% - (0.857em + 0.857em + 0.5em)); }
        body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
          padding: 0.429em;
          padding-right: 2em; }
        body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
          top: 50%;
          left: auto;
          margin-top: -.5em;
          right: .5em;
          color: #007ad9; }
    body .ui-listbox .ui-listbox-list {
      background-color: #ffffff; }
      body .ui-listbox .ui-listbox-list .ui-listbox-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
          color: #ffffff;
          background-color: #007ad9; }
        body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.5em 0 0; }
        body .ui-listbox .ui-listbox-list .ui-listbox-item label {
          display: inline-block;
          vertical-align: middle; }
    body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
      color: #333333;
      background-color: #eaeaea; }
    body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
      border: 1px solid #a6a6a6; }
    body .ui-listbox .ui-listbox-footer {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-top: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff; }
  body .ui-editor-container .ui-editor-toolbar {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4; }
  body .ui-editor-container .ui-editor-content {
    border: 1px solid #c8c8c8; }
    body .ui-editor-container .ui-editor-content .ql-editor {
      background-color: #ffffff;
      color: #333333; }
  body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
    color: #333333; }
  body .ui-editor-container .ql-stroke {
    stroke: #333333; }
  body .ui-editor-container .ql-picker-label {
    color: #333333; }
  body .ui-editor-container .ql-snow.ql-toolbar button:hover,
  body .ui-editor-container .ql-snow .ql-toolbar button:hover,
  body .ui-editor-container .ql-snow.ql-toolbar button.ql-active,
  body .ui-editor-container .ql-snow .ql-toolbar button.ql-active,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #007ad9; }
  body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #007ad9; }
  body .ui-rating a {
    text-align: center;
    display: inline-block;
    color: #333333;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
    body .ui-rating a.ui-rating-cancel {
      color: #e4018d; }
    body .ui-rating a .ui-rating-icon {
      font-size: 20px; }
  body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:hover {
    color: #007ad9; }
  body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a.ui-rating-cancel:hover {
    color: #b5019f; }
  body .ui-spinner .ui-spinner-input {
    padding-right: 2.429em; }
  body .ui-spinner .ui-spinner-button {
    width: 2em; }
    body .ui-spinner .ui-spinner-button.ui-spinner-up {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px; }
    body .ui-spinner .ui-spinner-button.ui-spinner-down {
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -.5em;
      margin-left: -.5em;
      width: 1em; }
  body .ui-fluid .ui-spinner .ui-spinner-input {
    padding-right: 2.429em; }
  body .ui-fluid .ui-spinner .ui-spinner-button {
    width: 2em; }
    body .ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
      left: 50%; }
  body .ui-slider {
    background-color: #c8c8c8;
    border: 0 none; }
    body .ui-slider.ui-slider-horizontal {
      height: 0.286em; }
      body .ui-slider.ui-slider-horizontal .ui-slider-handle {
        top: 50%;
        margin-top: -0.5715em; }
    body .ui-slider.ui-slider-vertical {
      width: 0.286em; }
      body .ui-slider.ui-slider-vertical .ui-slider-handle {
        left: 50%;
        margin-left: -0.5715em; }
    body .ui-slider .ui-slider-handle {
      height: 1.143em;
      width: 1.143em;
      background-color: #ffffff;
      border: 2px solid #666666;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
      -moz-transition: border-color 0.2s;
      -o-transition: border-color 0.2s;
      -webkit-transition: border-color 0.2s;
      transition: border-color 0.2s; }
    body .ui-slider .ui-slider-range {
      background-color: #007ad9; }
    body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
      background-color: 2px solid #666666;
      border: 2px solid #007ad9; }
  body .ui-datepicker {
    padding: 0.857em;
    min-width: 20em;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #a6a6a6; }
    body .ui-datepicker:not(.ui-datepicker-inline) {
      border: 1px solid #c8c8c8;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover,
    body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover {
      color: #007ad9; }
    body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
    body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
      outline: 0 none;
      color: #007ad9; }
    body .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
      background-color: #eaeaea; }
    body .ui-datepicker:not(.ui-state-disabled) .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
      background-color: #eaeaea; }
    body .ui-datepicker .ui-datepicker-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      background-color: #ffffff;
      color: #333333;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
      body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
        cursor: pointer;
        top: 0;
        color: #a6a6a6;
        -moz-transition: color 0.2s;
        -o-transition: color 0.2s;
        -webkit-transition: color 0.2s;
        transition: color 0.2s; }
      body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
        margin: 0;
        padding: 0;
        line-height: 1; }
        body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
          margin-top: -.35em;
          margin-bottom: 0; }
    body .ui-datepicker table {
      font-size: 14px;
      margin: 0.857em 0 0 0; }
      body .ui-datepicker table th {
        padding: 0.5em; }
      body .ui-datepicker table td {
        padding: 0.5em; }
        body .ui-datepicker table td > a, body .ui-datepicker table td > span {
          display: block;
          text-align: center;
          color: #333333;
          padding: 0.5em;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
          border-radius: 3px; }
          body .ui-datepicker table td > a.ui-state-active, body .ui-datepicker table td > span.ui-state-active {
            color: #ffffff;
            background-color: #007ad9; }
        body .ui-datepicker table td > a {
          cursor: pointer; }
        body .ui-datepicker table td.ui-datepicker-today > a, body .ui-datepicker table td.ui-datepicker-today > span {
          background-color: #d0d0d0;
          color: #333333; }
          body .ui-datepicker table td.ui-datepicker-today > a.ui-state-active, body .ui-datepicker table td.ui-datepicker-today > span.ui-state-active {
            color: #ffffff;
            background-color: #007ad9; }
        body .ui-datepicker table td.ui-datepicker-weekheader, body .ui-datepicker table td.ui-datepicker-weeknumber {
          border-right: 1px solid #a6a6a6; }
    body .ui-datepicker .ui-datepicker-buttonbar {
      border-top: 1px solid #d8dae2; }
    body .ui-datepicker .ui-timepicker {
      border: 0 none;
      border-top: 1px solid #d8dae2;
      padding: 0.857em; }
      body .ui-datepicker .ui-timepicker a {
        color: #333333;
        font-size: 1.286em; }
        body .ui-datepicker .ui-timepicker a:hover {
          color: #007ad9; }
      body .ui-datepicker .ui-timepicker span {
        font-size: 1.286em; }
    body .ui-datepicker .ui-monthpicker .ui-monthpicker-month {
      color: #333333; }
      body .ui-datepicker .ui-monthpicker .ui-monthpicker-month.ui-state-active {
        color: #ffffff;
        background-color: #007ad9; }
    body .ui-datepicker.ui-datepicker-timeonly {
      padding: 0; }
      body .ui-datepicker.ui-datepicker-timeonly .ui-timepicker {
        border-top: 0 none; }
    body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
      border-right: 1px solid #d8dae2;
      padding-right: 0.857em;
      padding-left: 0.857em;
      padding-top: 0;
      padding-bottom: 0; }
      body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:first-child {
        padding-left: 0; }
      body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:last-child {
        padding-right: 0;
        border-right: 0 none; }
  body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none; }
    body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error), body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
      border-right: 0 none; }
  body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
    width: 2.357em;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .ui-fluid .ui-calendar.ui-calendar-w-btn input.ui-inputtext {
    width: calc(100% - 2.357em); }
  body .ui-fileupload .ui-fileupload-buttonbar {
    background-color: #f4f4f4;
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: #333333;
    border-bottom: 0 none; }
    body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
      margin-right: 8px; }
  body .ui-fileupload .ui-fileupload-content {
    background-color: #ffffff;
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: #333333; }
  body .ui-fileupload .ui-progressbar {
    top: 0; }
  body .ui-fileupload-choose:not(.ui-state-disabled):hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .ui-fileupload-choose:not(.ui-state-disabled):active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
  body .ui-fileupload-choose.ui-state-focus {
    outline: 0 none;
    outline-offset: 0px; }
  body .ui-password-panel {
    padding: 12px;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-inputgroup .ui-inputgroup-addon {
    border-color: #a6a6a6;
    background-color: #eaeaea;
    color: #848484;
    padding: 0.429em;
    min-width: 2em; }
    body .ui-inputgroup .ui-inputgroup-addon:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .ui-inputgroup .ui-inputgroup-addon:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox {
      position: relative; }
      body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox .ui-chkbox {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px; }
    body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton {
      position: relative; }
      body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton .ui-radiobutton {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px; }
  body .ui-inputgroup .ui-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .ui-inputgroup .ui-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  body .ui-fluid .ui-inputgroup .ui-button {
    width: auto; }
    body .ui-fluid .ui-inputgroup .ui-button.ui-button-icon-only {
      width: 2.357em; }
  body ::-webkit-input-placeholder {
    color: #666666; }
  body :-moz-placeholder {
    color: #666666; }
  body ::-moz-placeholder {
    color: #666666; }
  body :-ms-input-placeholder {
    color: #666666; }
  body .ui-inputtext.ng-dirty.ng-invalid,
  body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
  body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
  body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
  body p-chips.ng-dirty.ng-invalid > .ui-inputtext,
  body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
  body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
  body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
  body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
  body p-listbox.ng-dirty.ng-invalid .ui-inputtext,
  body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
  body p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
  body p-selectbutton.ng-dirty.ng-invalid .ui-button,
  body p-togglebutton.ng-dirty.ng-invalid .ui-button {
    border: 1px solid #a80000; }

body .ui-button {
  margin: 0;
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .ui-button:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .ui-button:enabled:focus {
    outline: 0 none;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .ui-button:enabled:active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
  body .ui-button.ui-button-text-only .ui-button-text {
    padding: 0.429em 1em; }
  body .ui-button.ui-button-text-icon-left .ui-button-text {
    padding: 0.429em 1em 0.429em 2em; }
  body .ui-button.ui-button-text-icon-right .ui-button-text {
    padding: 0.429em 2em 0.429em 1em; }
  body .ui-button.ui-button-icon-only {
    width: 2.357em; }
    body .ui-button.ui-button-icon-only .ui-button-text {
      padding: 0.429em; }
  body .ui-button.ui-button-raised {
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15); }
  body .ui-button.ui-button-rounded {
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px; }
body .ui-fluid .ui-button-icon-only {
  width: 2.357em; }
body .ui-togglebutton {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .ui-togglebutton .ui-button-icon-left {
    color: #666666; }
  body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333; }
    body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
      color: #212121; }
  body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333;
    outline: 0 none; }
    body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
      color: #212121; }
  body .ui-togglebutton.ui-state-active {
    background-color: #007ad9;
    border-color: #007ad9;
    color: #ffffff; }
    body .ui-togglebutton.ui-state-active .ui-button-icon-left {
      color: #ffffff; }
    body .ui-togglebutton.ui-state-active:hover {
      background-color: #116fbf;
      border-color: #116fbf;
      color: #ffffff; }
      body .ui-togglebutton.ui-state-active:hover .ui-button-icon-left {
        color: #ffffff; }
    body .ui-togglebutton.ui-state-active.ui-state-focus {
      background-color: #005b9f;
      border-color: #005b9f;
      color: #ffffff; }
      body .ui-togglebutton.ui-state-active.ui-state-focus .ui-button-icon-left {
        color: #ffffff; }
body .ui-selectbutton .ui-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .ui-selectbutton .ui-button .ui-button-icon-left {
    color: #666666; }
  body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333; }
    body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
      color: #212121; }
  body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333;
    outline: 0 none; }
    body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus .ui-button-icon-left {
      color: #212121; }
  body .ui-selectbutton .ui-button.ui-state-active {
    background-color: #007ad9;
    border-color: #007ad9;
    color: #ffffff; }
    body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left {
      color: #ffffff; }
    body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover {
      background-color: #116fbf;
      border-color: #116fbf;
      color: #ffffff; }
      body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-button-icon-left {
        color: #ffffff; }
    body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus {
      background-color: #005b9f;
      border-color: #005b9f;
      color: #ffffff; }
      body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus .ui-button-icon-left {
        color: #ffffff; }
  body .ui-selectbutton .ui-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .ui-selectbutton .ui-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
@media (max-width: 640px) {
  body .ui-buttonset:not(.ui-splitbutton) .ui-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; } }
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent; }
body .ui-splitbutton.ui-buttonset .ui-menu {
  min-width: 100%; }
body .ui-button.ui-state-default.ui-button-secondary, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4; }
  body .ui-button.ui-state-default.ui-button-secondary:enabled:hover, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:hover {
    background-color: #c8c8c8;
    color: #333333;
    border-color: #c8c8c8; }
  body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .ui-button.ui-state-default.ui-button-secondary:enabled:active, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:active {
    background-color: #a0a0a0;
    color: #333333;
    border-color: #a0a0a0; }
body .ui-button.ui-state-default.ui-button-info, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9; }
  body .ui-button.ui-state-default.ui-button-info:enabled:hover, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .ui-button.ui-state-default.ui-button-info:enabled:active, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
body .ui-button.ui-state-default.ui-button-success, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #34A835;
  border: 1px solid #34A835; }
  body .ui-button.ui-state-default.ui-button-success:enabled:hover, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:hover {
    background-color: #107D11;
    color: #ffffff;
    border-color: #107D11; }
  body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #aae5aa;
    -moz-box-shadow: 0 0 0 0.2em #aae5aa;
    box-shadow: 0 0 0 0.2em #aae5aa; }
  body .ui-button.ui-state-default.ui-button-success:enabled:active, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:active {
    background-color: #0C6B0D;
    color: #ffffff;
    border-color: #0C6B0D; }
body .ui-button.ui-state-default.ui-button-warning, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default {
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01; }
  body .ui-button.ui-state-default.ui-button-warning:enabled:hover, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:hover {
    background-color: #ED990B;
    color: #333333;
    border-color: #ED990B; }
  body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #ffeab4;
    -moz-box-shadow: 0 0 0 0.2em #ffeab4;
    box-shadow: 0 0 0 0.2em #ffeab4; }
  body .ui-button.ui-state-default.ui-button-warning:enabled:active, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:active {
    background-color: #D38B10;
    color: #333333;
    border-color: #D38B10; }
body .ui-button.ui-state-default.ui-button-danger, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  color: #ffffff;
  background-color: #e91224;
  border: 1px solid #e91224; }
  body .ui-button.ui-state-default.ui-button-danger:enabled:hover, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:hover {
    background-color: #c01120;
    color: #ffffff;
    border-color: #c01120; }
  body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #f9b4ba;
    -moz-box-shadow: 0 0 0 0.2em #f9b4ba;
    box-shadow: 0 0 0 0.2em #f9b4ba; }
  body .ui-button.ui-state-default.ui-button-danger:enabled:active, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:active {
    background-color: #a90000;
    color: #ffffff;
    border-color: #a90000; }

body .ui-widget-content p {
  line-height: 1.5;
  margin: 0; }
body .ui-panel {
  padding: 0;
  border: 0 none; }
  body .ui-panel .ui-panel-titlebar {
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em;
    background-color: #f4f4f4;
    color: #333333;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .ui-panel .ui-panel-titlebar .ui-panel-title {
      vertical-align: middle;
      font-weight: 700; }
    body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
      margin: 0;
      position: relative;
      font-size: 14px;
      color: #848484;
      border: 1px solid transparent;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
        color: #333333; }
  body .ui-panel .ui-panel-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
    border-top: 0 none; }
  body .ui-panel .ui-panel-footer {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    border-top: 0 none;
    margin: 0; }
body .ui-fieldset {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333; }
  body .ui-fieldset .ui-fieldset-legend a {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: #333333;
    background-color: #f4f4f4;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: .5em;
      color: #848484; }
    body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
      padding: 0; }
  body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: #333333; }
    body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover .ui-fieldset-toggler {
      color: #333333; }
  body .ui-fieldset .ui-fieldset-content {
    padding: 0; }
body .ui-accordion .ui-accordion-header {
  margin-bottom: 2px; }
  body .ui-accordion .ui-accordion-header a {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: #333333;
    background-color: #f4f4f4;
    color: #333333;
    font-weight: 700;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
      color: #848484; }
  body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    color: #333333; }
    body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
      color: #333333; }
  body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
    background-color: #007ad9;
    border: 1px solid #007ad9;
    color: #ffffff; }
    body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
      color: #ffffff; }
  body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
    border: 1px solid #005b9f;
    background-color: #005b9f;
    color: #ffffff; }
    body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
      color: #ffffff; }
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none; }
  body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
    padding: 0;
    background: transparent;
    border: 0 none; }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
      border: 1px solid #c8c8c8;
      background-color: #f4f4f4;
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
        float: none;
        display: inline-block;
        color: #333333;
        padding: 0.571em 1em;
        font-weight: 700; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
          margin-right: 0.5em; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
          margin-left: 0.5em; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
        color: #848484;
        margin: 0 0.5em 0 0;
        vertical-align: middle; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
        background-color: #dbdbdb;
        border: 1px solid #dbdbdb; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
          color: #333333; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
          color: #333333; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
        background-color: #007ad9;
        border: 1px solid #007ad9; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
          color: #ffffff; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
          color: #ffffff; }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
          border: 1px solid #005b9f;
          background-color: #005b9f; }
          body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
            color: #ffffff; }
            body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
              color: #ffffff; }
      body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
        cursor: pointer; }
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px; }
  body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    margin-right: 2px; }
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px; }
  body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
    margin-right: 2px; }
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px; }
  body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
    margin-bottom: 2px; }
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px; }
  body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
    margin-bottom: 2px; }
body .ui-tabview .ui-tabview-panels {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333; }
  body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
    padding: 0; }
body .ui-toolbar {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em; }
  body .ui-toolbar button {
    vertical-align: middle; }
  body .ui-toolbar .ui-toolbar-separator {
    vertical-align: middle;
    color: #848484;
    margin: 0 0.5em; }
body .ui-card {
  background-color: #ffffff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }

body .ui-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0; }
  body .ui-paginator .ui-paginator-first,
  body .ui-paginator .ui-paginator-prev,
  body .ui-paginator .ui-paginator-next,
  body .ui-paginator .ui-paginator-last {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em;
    padding: 0;
    margin: 0;
    vertical-align: top;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
    body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
    body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
    body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
      background-color: #e0e0e0;
      color: #333333; }
  body .ui-paginator .ui-paginator-current {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    line-height: 2.286em; }
  body .ui-paginator .ui-dropdown {
    border: 0 none; }
    body .ui-paginator .ui-dropdown .ui-dropdown-trigger, body .ui-paginator .ui-dropdown .ui-dropdown-label {
      color: #848484; }
    body .ui-paginator .ui-dropdown:hover .ui-dropdown-trigger, body .ui-paginator .ui-dropdown:hover .ui-dropdown-label {
      color: #333333; }
  body .ui-paginator .ui-paginator-first:before {
    position: relative;
    top: 1px; }
  body .ui-paginator .ui-paginator-prev:before {
    position: relative; }
  body .ui-paginator .ui-paginator-next:before {
    position: relative;
    top: 1px; }
  body .ui-paginator .ui-paginator-last:before {
    position: relative;
    top: 1px; }
  body .ui-paginator .ui-paginator-pages {
    vertical-align: top;
    display: inline-block;
    padding: 0; }
    body .ui-paginator .ui-paginator-pages .ui-paginator-page {
      color: #848484;
      height: 2.286em;
      min-width: 2.286em;
      border: 0 none;
      line-height: 2.286em;
      padding: 0;
      margin: 0;
      vertical-align: top;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
        background-color: #007ad9;
        color: #ffffff; }
      body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
        background-color: #e0e0e0;
        color: #333333; }
  body .ui-paginator .ui-dropdown {
    margin-left: 0.5em;
    height: 2.286em;
    min-width: auto; }
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center; }
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700; }
body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700; }
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4; }
body .ui-table .ui-table-tbody > tr > td {
  padding: 0.571em 0.857em; }
body .ui-table .ui-table-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff; }
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #848484; }
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333; }
  body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #333333; }
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }
  body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #ffffff; }
body .ui-table .ui-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; }
  body .ui-table .ui-editable-column input:focus {
    outline: 1px solid #007ad9;
    outline-offset: 2px; }
body .ui-table .ui-table-tbody > tr {
  background-color: #ffffff;
  color: #333333; }
  body .ui-table .ui-table-tbody > tr > td {
    background-color: inherit;
    border: 1px solid #c8c8c8; }
  body .ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff; }
    body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
      color: #ffffff; }
  body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
    background-color: #007ad9;
    color: #ffffff; }
  body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
    -webkit-box-shadow: inset 0 2px 0 0 #007ad9;
    -moz-box-shadow: inset 0 2px 0 0 #007ad9;
    box-shadow: inset 0 2px 0 0 #007ad9; }
  body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
    -webkit-box-shadow: inset 0 -2px 0 0 #007ad9;
    -moz-box-shadow: inset 0 -2px 0 0 #007ad9;
    box-shadow: inset 0 -2px 0 0 #007ad9; }
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f9f9f9; }
  body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff; }
    body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
      color: #ffffff; }
  body .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
    background-color: #007ad9;
    color: #ffffff; }
body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333; }
body .ui-table .ui-column-resizer-helper {
  background-color: #007ad9; }
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: 1px solid #c8c8c8; }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: 1px solid #c8c8c8; }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr > td {
    border: 0 none; } }
body .ui-datagrid .ui-datagrid-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .ui-datagrid .ui-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-datagrid .ui-datagrid-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .ui-datalist .ui-datalist-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .ui-datalist .ui-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-datalist .ui-datalist-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .ui-datascroller .ui-datascroller-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .ui-datascroller .ui-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-datascroller .ui-datascroller-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .ui-virtualscroller .ui-virtualscroller-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .ui-virtualscroller .ui-virtualscroller-content {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333; }
  body .ui-virtualscroller .ui-virtualscroller-content .ui-virtualscroller-list li {
    border-bottom: 1px solid #c8c8c8; }
body .ui-virtualscroller .ui-virtualscroller-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .ui-dataview .ui-dataview-header {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .ui-dataview .ui-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-dataview .ui-dataview-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .fc th {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  color: #333333;
  padding: 0.571em 1em; }
body .fc td.ui-widget-content {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  color: #333333; }
body .fc td.fc-head-container {
  border: 1px solid #c8c8c8; }
body .fc .fc-row {
  border-right: 1px solid #c8c8c8; }
body .fc .fc-event {
  background-color: #116fbf;
  border: 1px solid #116fbf;
  color: #ffffff; }
body .fc .fc-toolbar .fc-button {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .fc .fc-toolbar .fc-button:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: 'PrimeIcons' !important;
    text-indent: 0; }
    body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
      content: ""; }
  body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: 'PrimeIcons' !important;
    text-indent: 0; }
    body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
      content: ""; }
  body .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0px;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
body .fc .fc-divider {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8; }
body .ui-fluid .fc .fc-toolbar .fc-button {
  width: auto; }
body .ui-picklist .ui-picklist-buttons button {
  font-size: 16px; }
body .ui-picklist .ui-picklist-caption {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none; }
  body .ui-picklist .ui-picklist-filter-container input.ui-picklist-filter {
    width: 100%;
    padding-right: 2em;
    text-indent: 0; }
  body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
    top: 50%;
    margin-top: -.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9; }
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em; }
body .ui-picklist .ui-picklist-list {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0; }
  body .ui-picklist .ui-picklist-list .ui-picklist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: #333333;
    background-color: transparent; }
    body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.ui-state-highlight):hover {
      background-color: #eaeaea;
      color: #333333; }
    body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
      background-color: #007ad9;
      color: #ffffff; }
  body .ui-picklist .ui-picklist-list .ui-picklist-droppoint-highlight {
    background-color: #007ad9; }
@media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em; } }
body .ui-orderlist .ui-orderlist-controls {
  padding: 0.571em 1em; }
  body .ui-orderlist .ui-orderlist-controls button {
    font-size: 16px; }
body .ui-orderlist .ui-orderlist-caption {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .ui-orderlist .ui-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none; }
  body .ui-orderlist .ui-orderlist-filter-container input.ui-inputtext {
    width: 100%;
    padding-right: 2em;
    text-indent: 0; }
  body .ui-orderlist .ui-orderlist-filter-container .ui-orderlist-filter-icon {
    top: 50%;
    margin-top: -.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9; }
body .ui-orderlist .ui-orderlist-list {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0; }
  body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: #333333;
    background-color: transparent; }
    body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:not(.ui-state-highlight):hover {
      background-color: #eaeaea;
      color: #333333; }
    body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
      background-color: #007ad9;
      color: #ffffff; }
  body .ui-orderlist .ui-orderlist-list .ui-orderlist-droppoint-highlight {
    background-color: #007ad9; }
body .ui-tree {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
  body .ui-tree .ui-tree-container {
    padding: 0.286em;
    margin: 0; }
    body .ui-tree .ui-tree-container .ui-treenode {
      padding: 0.143em 0; }
      body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
        padding: 0;
        border: 1px solid transparent; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
          vertical-align: middle;
          display: inline-block;
          float: none;
          margin: 0 0.143em 0 0;
          color: #848484; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
          vertical-align: middle;
          display: inline-block;
          margin: 0 0.143em 0 0;
          color: #848484; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
          margin: 0;
          vertical-align: middle;
          display: inline-block;
          padding: 0.286em; }
          body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
            background-color: #007ad9;
            color: #ffffff; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
          margin: 0 0.5em 0 0; }
          body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
            margin: 1px 0 0 0; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
          background-color: #eaeaea;
          color: #333333; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
          line-height: inherit; }
        body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
          background-color: #eaeaea;
          color: #333333; }
    body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
      background-color: #007ad9; }
  body .ui-tree.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0; }
    body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
      border: 1px solid #c8c8c8;
      background-color: #ffffff;
      color: #333333;
      padding: 0.571em 1em; }
      body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
        background-color: #007ad9;
        color: #ffffff; }
      body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
        color: #007ad9; }
      body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
        background-color: inherit;
        color: inherit; }
      body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
        background-color: #eaeaea;
        color: #333333; }
  body .ui-tree .ui-tree-filter-container .ui-tree-filter {
    width: 100%;
    padding-right: 2em; }
  body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -.5em;
    right: .8em;
    color: #007ad9; }
body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333; }
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }
  body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
    color: #00325a; }
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #c8c8c8; }
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #c8c8c8;
  border-color: #c8c8c8; }
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #c8c8c8;
  border-color: #c8c8c8; }
body .ui-organizationchart .ui-organizationchart-node-content {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -.7em;
  margin-left: -.46em;
  color: #848484; }
body .ui-treetable .ui-treetable-caption,
body .ui-treetable .ui-treetable-summary {
  background-color: #f4f4f4;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center; }
body .ui-treetable .ui-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700; }
body .ui-treetable .ui-treetable-summary {
  border-top: 0 none;
  font-weight: 700; }
body .ui-treetable .ui-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4; }
body .ui-treetable .ui-treetable-tbody > tr > td {
  padding: 0.571em 0.857em; }
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #ffffff; }
body .ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #848484; }
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #e0e0e0;
  color: #333333; }
  body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #333333; }
body .ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff; }
  body .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #ffffff; }
body .ui-treetable .ui-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; }
  body .ui-treetable .ui-editable-column input:focus {
    outline: 1px solid #007ad9;
    outline-offset: 2px; }
body .ui-treetable .ui-treetable-tbody > tr {
  background-color: #ffffff;
  color: #333333; }
  body .ui-treetable .ui-treetable-tbody > tr > td {
    background-color: inherit;
    border: 1px solid #c8c8c8; }
    body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-toggler {
      color: #848484;
      vertical-align: middle; }
    body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-chkbox {
      vertical-align: middle;
      margin-right: 0.5em; }
  body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff; }
    body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td {
      background-color: inherit;
      border: 1px solid #c8c8c8; }
      body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td .ui-treetable-toggler {
        color: #ffffff; }
  body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
    background-color: #007ad9;
    color: #ffffff; }
    body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td {
      background-color: inherit;
      border: 1px solid #c8c8c8; }
      body .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected > td .ui-treetable-toggler {
        color: #ffffff; }
body .ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333; }
body .ui-treetable .ui-column-resizer-helper {
  background-color: #007ad9; }
body .ui-carousel .ui-carousel-content .ui-carousel-prev,
body .ui-carousel .ui-carousel-content .ui-carousel-next {
  background-color: #ffffff;
  border: solid 1px rgba(178, 193, 205, 0.64);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0.2em;
  color: #333333;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }
  body .ui-carousel .ui-carousel-content .ui-carousel-prev:not(.ui-state-disabled):hover,
  body .ui-carousel .ui-carousel-content .ui-carousel-next:not(.ui-state-disabled):hover {
    background-color: #ffffff;
    color: #007ad9;
    border-color: solid 1px rgba(178, 193, 205, 0.64); }
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item > .ui-button {
  border-color: transparent;
  background-color: transparent; }
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon {
  width: 20px;
  height: 6px;
  background-color: #b2c1cd;
  margin: 0 0.2em; }
  body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon::before {
    content: ' '; }
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item.ui-state-highlight .ui-carousel-dot-icon {
  background-color: #007ad9; }

body .ui-messages {
  padding: 1em;
  margin: 1em 0; }
  body .ui-messages.ui-messages-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121; }
    body .ui-messages.ui-messages-info .ui-messages-icon {
      color: #212121; }
    body .ui-messages.ui-messages-info .ui-messages-close {
      color: #212121; }
  body .ui-messages.ui-messages-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121; }
    body .ui-messages.ui-messages-success .ui-messages-icon {
      color: #212121; }
    body .ui-messages.ui-messages-success .ui-messages-close {
      color: #212121; }
  body .ui-messages.ui-messages-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121; }
    body .ui-messages.ui-messages-warn .ui-messages-icon {
      color: #212121; }
    body .ui-messages.ui-messages-warn .ui-messages-close {
      color: #212121; }
  body .ui-messages.ui-messages-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121; }
    body .ui-messages.ui-messages-error .ui-messages-icon {
      color: #212121; }
    body .ui-messages.ui-messages-error .ui-messages-close {
      color: #212121; }
  body .ui-messages .ui-messages-close {
    top: .25em;
    right: .5em;
    font-size: 1.5em; }
  body .ui-messages .ui-messages-icon {
    font-size: 2em; }
body .ui-message {
  padding: 0.429em;
  margin: 0; }
  body .ui-message.ui-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121; }
    body .ui-message.ui-message-info .ui-message-icon {
      color: #212121; }
  body .ui-message.ui-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121; }
    body .ui-message.ui-message-success .ui-message-icon {
      color: #212121; }
  body .ui-message.ui-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121; }
    body .ui-message.ui-message-warn .ui-message-icon {
      color: #212121; }
  body .ui-message.ui-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121; }
    body .ui-message.ui-message-error .ui-message-icon {
      color: #212121; }
  body .ui-message .ui-message-icon {
    font-size: 1.25em; }
  body .ui-message .ui-message-text {
    font-size: 1em; }
body .ui-growl {
  top: 70px; }
  body .ui-growl .ui-growl-item-container {
    margin: 0 0 1em 0;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-growl .ui-growl-item-container .ui-growl-item {
      padding: 1em; }
      body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-message {
        margin: 0 0 0 4em; }
      body .ui-growl .ui-growl-item-container .ui-growl-item .ui-growl-image {
        font-size: 2.571em; }
    body .ui-growl .ui-growl-item-container.ui-growl-message-info {
      background-color: #7fbcec;
      border: 0 none;
      color: #212121; }
      body .ui-growl .ui-growl-item-container.ui-growl-message-info .ui-growl-image {
        color: #212121; }
    body .ui-growl .ui-growl-item-container.ui-growl-message-success {
      background-color: #b7d8b7;
      border: 0 none;
      color: #212121; }
      body .ui-growl .ui-growl-item-container.ui-growl-message-success .ui-growl-image {
        color: #212121; }
    body .ui-growl .ui-growl-item-container.ui-growl-message-warn {
      background-color: #ffe399;
      border: 0 none;
      color: #212121; }
      body .ui-growl .ui-growl-item-container.ui-growl-message-warn .ui-growl-image {
        color: #212121; }
    body .ui-growl .ui-growl-item-container.ui-growl-message-error {
      background-color: #f8b7bd;
      border: 0 none;
      color: #212121; }
      body .ui-growl .ui-growl-item-container.ui-growl-message-error .ui-growl-image {
        color: #212121; }
body .ui-toast .ui-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0; }
  body .ui-toast .ui-toast-message.ui-toast-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121; }
    body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
      color: #212121; }
  body .ui-toast .ui-toast-message.ui-toast-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121; }
    body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
      color: #212121; }
  body .ui-toast .ui-toast-message.ui-toast-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121; }
    body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-close-icon {
      color: #212121; }
  body .ui-toast .ui-toast-message.ui-toast-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121; }
    body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
      color: #212121; }

body .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4); }
body .ui-overlaypanel {
  background-color: #ffffff;
  color: #333333;
  padding: 0;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .ui-overlaypanel .ui-overlaypanel-content {
    padding: 0.571em 1em; }
  body .ui-overlaypanel .ui-overlaypanel-close {
    background-color: #007ad9;
    color: #ffffff;
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: -0.769em;
    right: -0.769em;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .ui-overlaypanel .ui-overlaypanel-close:hover {
      background-color: #005b9f;
      color: #ffffff; }
    body .ui-overlaypanel .ui-overlaypanel-close .ui-overlaypanel-close-icon {
      line-height: inherit; }
  body .ui-overlaypanel:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff; }
  body .ui-overlaypanel:before {
    border-color: rgba(200, 200, 200, 0);
    border-bottom-color: #c8c8c8; }
  body .ui-overlaypanel.ui-overlaypanel-flipped:after {
    border-top-color: #ffffff; }
  body .ui-overlaypanel.ui-overlaypanel-flipped:before {
    border-top-color: #c8c8c8; }
body .ui-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-dialog .ui-dialog-titlebar {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: #333333;
    padding: 1em;
    font-weight: 700;
    border-bottom: 0 none; }
    body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
      margin: 0;
      float: none; }
    body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
      color: #848484;
      border: 0 none;
      padding: 0;
      margin-left: 0.5em;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
        color: #333333; }
  body .ui-dialog .ui-dialog-content {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    padding: 0.571em 1em; }
  body .ui-dialog .ui-dialog-footer {
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
    margin: 0;
    text-align: right;
    position: relative;
    top: -1px; }
    body .ui-dialog .ui-dialog-footer button {
      margin: 0 0.5em 0 0;
      width: auto; }
  body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
    padding: 1.5em; }
    body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span {
      float: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 14px;
      margin: 0; }
      body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span.ui-icon {
        margin-right: .35em;
        font-size: 16px; }
body .ui-sidebar {
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-sidebar .ui-sidebar-close {
    color: #848484; }
    body .ui-sidebar .ui-sidebar-close:hover {
      color: #333333; }
body .ui-tooltip .ui-tooltip-text {
  background-color: #333333;
  color: #ffffff;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #333333; }
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #333333; }
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #333333; }
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #333333; }
body .ui-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-lightbox .ui-lightbox-caption {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: #333333;
    padding: 0.571em 1em;
    font-weight: 700; }
    body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
      color: #333333;
      margin: 0; }
    body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
      padding: 0;
      color: #848484;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
        color: #333333; }
  body .ui-lightbox .ui-lightbox-content-wrapper {
    overflow: hidden;
    background-color: #ffffff;
    color: #333333;
    border: 0 none;
    padding: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      font-size: 3em;
      color: #ffffff;
      margin-top: -.5em; }
      body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2); }
    body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading ~ a {
      display: none; }

body .ui-breadcrumb {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em; }
  body .ui-breadcrumb ul li .ui-menuitem-link {
    color: #333333;
    margin: 0; }
  body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
    margin: 0 0.5em 0 0.5em;
    color: #848484; }
  body .ui-breadcrumb ul li:first-child a {
    color: #848484;
    margin: 0; }
  body .ui-breadcrumb ul li .ui-menuitem-icon {
    color: #848484; }
body .ui-steps {
  position: relative; }
  body .ui-steps .ui-steps-item {
    background-color: transparent;
    text-align: center; }
    body .ui-steps .ui-steps-item .ui-menuitem-link {
      display: inline-block;
      text-align: center;
      background-color: transparent;
      overflow: hidden; }
      body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        display: inline-block;
        color: #333333;
        background-color: #ffffff;
        border: 1px solid #c8c8c8;
        position: relative;
        top: 16px;
        margin-bottom: 14px;
        width: 28px;
        height: 28px;
        font-size: 16px;
        line-height: 24px;
        text-align: center; }
      body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
        display: block;
        margin-top: 6px;
        color: #848484; }
    body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
      background: #007ad9;
      color: #ffffff; }
    body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
      font-weight: 700;
      color: #333333; }
    body .ui-steps .ui-steps-item:last-child .ui-menuitem-link {
      display: block; }
  body .ui-steps:before {
    content: ' ';
    border-top: 1px solid #c8c8c8;
    width: 100%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute; }
body .ui-menu .ui-menuitem-link,
body .ui-menubar .ui-menuitem-link,
body .ui-tieredmenu .ui-menuitem-link,
body .ui-contextmenu .ui-menuitem-link,
body .ui-megamenu .ui-menuitem-link,
body .ui-slidemenu .ui-menuitem-link {
  padding: 0.714em 0.857em;
  color: #333333;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .ui-menu .ui-menuitem-link .ui-menuitem-text,
  body .ui-menubar .ui-menuitem-link .ui-menuitem-text,
  body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text,
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text,
  body .ui-megamenu .ui-menuitem-link .ui-menuitem-text,
  body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
    color: #333333; }
  body .ui-menu .ui-menuitem-link .ui-menuitem-icon,
  body .ui-menubar .ui-menuitem-link .ui-menuitem-icon,
  body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-icon,
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon,
  body .ui-megamenu .ui-menuitem-link .ui-menuitem-icon,
  body .ui-slidemenu .ui-menuitem-link .ui-menuitem-icon {
    color: #333333;
    margin-right: 0.5em; }
  body .ui-menu .ui-menuitem-link:hover,
  body .ui-menubar .ui-menuitem-link:hover,
  body .ui-tieredmenu .ui-menuitem-link:hover,
  body .ui-contextmenu .ui-menuitem-link:hover,
  body .ui-megamenu .ui-menuitem-link:hover,
  body .ui-slidemenu .ui-menuitem-link:hover {
    background-color: #eaeaea; }
    body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text,
    body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-text,
    body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-text,
    body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-text,
    body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-text,
    body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-text {
      color: #333333; }
    body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon,
    body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-icon,
    body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-icon,
    body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-icon,
    body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-icon,
    body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-icon {
      color: #333333; }
body .ui-menu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8; }
  body .ui-menu .ui-menuitem {
    margin: 0; }
  body .ui-menu.ui-shadow {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-menu .ui-submenu-header {
    margin: 0;
    padding: 0.714em 0.857em;
    color: #333333;
    background-color: #f4f4f4;
    font-weight: 700;
    border: 0 none; }
  body .ui-menu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .ui-menubar {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8; }
  body .ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
    padding: 0.714em 0.857em; }
  body .ui-menubar .ui-menubar-root-list > .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 0 0 0 1px; }
  body .ui-menubar .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
    body .ui-menubar .ui-submenu-list .ui-menuitem {
      margin: 0; }
    body .ui-menubar .ui-submenu-list .ui-menu-separator {
      border: 1px solid #c8c8c8;
      border-width: 1px 0 0 0; }
  body .ui-menubar .ui-menuitem {
    margin: 0; }
    body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: #eaeaea; }
      body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
        color: #333333; }
      body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
        color: #333333; }
body .ui-contextmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-contextmenu .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-contextmenu .ui-menuitem {
    margin: 0; }
    body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
      right: 0.429em; }
    body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: #eaeaea; }
      body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
        color: #333333; }
      body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
        color: #333333; }
  body .ui-contextmenu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .ui-tieredmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8; }
  body .ui-tieredmenu .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-tieredmenu .ui-menuitem {
    margin: 0; }
    body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
      right: 0.429em; }
    body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: #eaeaea; }
      body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
        color: #333333; }
      body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
        color: #333333; }
  body .ui-tieredmenu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .ui-slidemenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8; }
  body .ui-slidemenu .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 0 none; }
  body .ui-slidemenu .ui-menuitem {
    margin: 0; }
    body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
      right: 0.429em; }
    body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: #eaeaea; }
      body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
        color: #333333; }
      body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
        color: #333333; }
  body .ui-slidemenu .ui-slidemenu-backward {
    margin: 0;
    padding: 0.571em 1em;
    color: #333333;
    background-color: #f4f4f4;
    font-weight: 700;
    border: 0 none; }
    body .ui-slidemenu .ui-slidemenu-backward:hover {
      background-color: #dbdbdb;
      color: #333333; }
  body .ui-slidemenu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .ui-tabmenu {
  border: 0 none; }
  body .ui-tabmenu .ui-tabmenu-nav {
    padding: 0;
    background: transparent;
    border-bottom: 1px solid #c8c8c8; }
    body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
      position: static;
      border: 1px solid #c8c8c8;
      background-color: #f4f4f4;
      margin-right: 2px;
      margin-bottom: -1px;
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }
      body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
        color: #333333;
        padding: 0.571em 1em; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-text {
          color: #333333;
          margin-right: 0.5em; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-icon {
          color: #848484;
          margin-right: 0.5em; }
      body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover {
        background-color: #dbdbdb;
        border: 1px solid #dbdbdb; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-text {
          color: #333333; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-icon {
          color: #333333; }
      body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
        background-color: #007ad9;
        border: 1px solid #007ad9; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-text {
          color: #ffffff; }
        body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-icon {
          color: #ffffff; }
body .ui-megamenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8; }
  body .ui-megamenu .ui-megamenu-submenu-header {
    margin: 0;
    padding: 0.571em 1em;
    color: #333333;
    background-color: #f4f4f4;
    font-weight: 700;
    border: 0 none; }
  body .ui-megamenu .ui-megamenu-panel {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .ui-megamenu .ui-menuitem {
    margin: 0; }
    body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
      right: 0.429em; }
    body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: #eaeaea; }
      body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
        color: #333333; }
      body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
        color: #333333; }
  body .ui-megamenu.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
    right: 0.429em; }
body .ui-panelmenu .ui-icon {
  position: static; }
body .ui-panelmenu .ui-panelmenu-header {
  padding: 0; }
  body .ui-panelmenu .ui-panelmenu-header > a {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: #333333;
    padding: 0.714em 0.857em;
    font-weight: 700;
    position: static;
    font-size: 14px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
      color: #848484; }
  body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
    outline: 0 none;
    border: 1px solid #dbdbdb;
    background-color: #dbdbdb;
    color: #333333; }
    body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
      color: #333333; }
  body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
    border: 1px solid #007ad9;
    background-color: #007ad9;
    color: #ffffff; }
    body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
      color: #ffffff; }
    body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
      outline: 0 none;
      border: 1px solid #005b9f;
      background-color: #005b9f;
      color: #ffffff; }
      body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
        color: #ffffff; }
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 2px; }
  body .ui-panelmenu .ui-panelmenu-panel:first-child {
    margin-top: 0; }
body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  margin-top: 0;
  position: static;
  border-top: 0 none; }
  body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
    margin: 0; }
    body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
      padding: 0.714em 0.857em;
      color: #333333; }
      body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
        background-color: #eaeaea; }
        body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-icon, body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-panelmenu-icon {
          color: #333333; }
        body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-text {
          color: #333333; }

body .ui-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea; }
  body .ui-progressbar .ui-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #007ad9; }
  body .ui-progressbar .ui-progressbar-label {
    margin-top: 0;
    color: #333333;
    line-height: 24px; }
body .ui-galleria .ui-galleria-nav-prev,
body .ui-galleria .ui-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #848484; }
  body .ui-galleria .ui-galleria-nav-prev:hover,
  body .ui-galleria .ui-galleria-nav-next:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 16px; }
body .ui-galleria .ui-galleria-nav-next {
  font-size: 16px; }
body .ui-terminal {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em; }
  body .ui-terminal .ui-terminal-input {
    font-size: 14px;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    height: 16px; }
  body .ui-terminal .ui-terminal-command {
    height: 16px; }
body .ui-inplace {
  min-height: 2.357em; }
  body .ui-inplace .ui-inplace-display {
    padding: 0.429em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .ui-inplace .ui-inplace-display:not(.ui-state-disabled):hover {
      background-color: #eaeaea;
      color: #333333; }
body .ui-fluid .ui-inplace.ui-inplace-closable .ui-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em); }